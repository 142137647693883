<template>
  <b-form-group>
    <validation-provider
        #default="{ errors }"
        :name="name"
        :rules="rules"
    >
      <b-form-checkbox
          :id="name"
          :value="value"
          :name="name"
          @input="handleInput"
      >
        <slot>Test</slot>
      </b-form-checkbox>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {BFormGroup, BFormCheckboxGroup, BFormCheckbox} from "bootstrap-vue";
import {ValidationProvider} from "vee-validate";

export default {
  name: "BlInput",
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    ValidationProvider,
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },
  props: ['name', 'rules', 'value']
}
</script>

<style scoped>

</style>
