<template>
  <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
    <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
      <!--Заголовок-->
      <b-card-title class="mb-1 fill"> Регистрация</b-card-title>
      <b-card-text class="mb-2">
        Введите свои данные чтобы получить доступ в систему
      </b-card-text>

      <!--Форма-->
      <validation-observer v-slot="{ invalid }">
        <b-form class="auth-register-form mt-2" @submit.prevent>
          <!-- Инпуты  -->
          <BlInput
              name="email"
              label="Email"
              rules="required|email"
              placeholder="email@gmail.com"
              input-type="text"
              v-model="email"
          ></BlInput>
          <BlInput
              name="firstName"
              label="Имя"
              rules="required|alpha_spaces"
              placeholder="Иван"
              input-type="text"
              v-model="firstName"
          ></BlInput>
          <BlInput
              name="lastName"
              label="Фамилия"
              rules="required|alpha_spaces"
              placeholder="Иванов"
              input-type="text"
              v-model="lastName"
          ></BlInput>
          <BlInput
              name="middleName"
              label="Отчество"

              placeholder="Иванович"
              input-type="text"
              v-model="middleName"
          ></BlInput>
          <BlInput
              name="phone"
              label="Телефон"
              rules="required|numeric"
              placeholder="37544 766 66 68"
              input-type="text"
              v-model="phone"
          ></BlInput>
          <BlInput
              name="companyName"
              label="Название организации, ИП"
              rules="required"
              placeholder="ООО 'Освещение'"
              input-type="text"
              v-model="company_name"
          ></BlInput>
          <BlInput
              name="companyAddress"
              label="Адрес организации"
              rules="required"
              input-type="text"
              placeholder="Беларусь, г. Минск, ул Аранская, 13 ком 9-10"
              v-model="company_address"
          ></BlInput>
          <BlInput
              name="position"
              label="Ваша должность"
              rules="required"
              placeholder="Менеджер по продажам"
              input-type="text"
              v-model="position"
          ></BlInput>
          <BlInput
              name="password"
              label="Пароль"
              rules="required|min:8"
              input-type="password"
              v-model="password"
          ></BlInput>
          <BlInput
              name="confirmPassword"
              label="Пароль"
              v-model="confirmPassword"
              rules="required|confirmed:password"
              input-type="password"
          ></BlInput>

          <!--Чекбокс-->
          <b-form-group>
            <BlCheckBox name="agree" v-model="status" rules="required">
              Я согласен с
              <b-link>политикой конфиденциальности</b-link>
            </BlCheckBox>
          </b-form-group>

          <!--Кнопка-->
          <b-button
              variant="primary"
              block
              type="submit"
              @click="register()"
              :disabled="invalid"
          >
            Зарегистрироваться
          </b-button>
        </b-form>
      </validation-observer>

      <!--Карта "уже есть аккаунт"-->
      <p class="text-center mt-2">
        <span>Уже есть аккаунт?</span>
        <b-link :to="{ name: 'login' }">
          <span>&nbsp; Войти в систему</span>
        </b-link>
      </p>
    </b-col>
  </b-col>
</template>

<script>

import '@/libs/custom-validate'
import api from '@/api';
import BlInput from "@/components/controls/BlInput";
import BlCheckBox from "@/components/controls/BlCheckBox";

import {ValidationProvider, ValidationObserver} from "vee-validate";
import {
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import utils from "@/utils";

export default {
  name: "RegisterForm",
  components: {
    BlCheckBox,
    BlInput,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      invalid: "",
      status: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      company_name: "",
      company_address: "",
      firstName: "",
      lastName: "",
      middleName: "",
      position: "",
    };

  },
  computed: {},
  methods: {
    register() {
      const self = this;
      api.auth
          .signup(
              this.email,
              this.phone,
              this.company_name,
              this.company_address,
              this.firstName,
              this.lastName,
              this.middleName,
              this.position,
              this.password
          )
          .then((response) => {
            const data = response.data;
            if (data && !data.error) {
              utils.notificate(self, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    // changeConfirmPassword(data) {
    //   this.confirmPassword = data.data
    // },
  },
};
</script>

<style scoped>
.fill {
  margin-top: 100px;
}
</style>
