<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <ByLedLogo />

      <PictureComp>
        <b-img
          fluid
          src="@/assets/images/pages/register-v2-dark.svg"
          alt="Login V2"
        />
      </PictureComp>

        <RegisterForm />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import ByLedLogo from '../../../core/ByLedLogo.vue'
import PictureComp from '../../../core/PictureComp.vue'
import RegisterForm from './RegisterForm.vue'

export default {
  components: {
    RegisterForm,
    PictureComp,
    ByLedLogo,
    BRow,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            username: this.username,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push('/')
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
